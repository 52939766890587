import React, { useEffect, useState } from "react";
import { AlertPopup } from "@metlife-one-opps/alert-popup";
import { Card } from "@metlife-one-opps/card";
import { useLoader } from "@metlife-one-opps/hooks";
import { MetLifeButton } from "@metlife-one-opps/metlife-button";
import { MetLifeGradientRow } from "@metlife-one-opps/metlife-gradient-row";
import { MetLifeInput, MetLifeRadioInput } from "@metlife-one-opps/metlife-input";
import { Modal } from "@metlife-one-opps/modal";
import {
  createPaymentService,
  introspectToken,
  startPayment,
  updateMobileNumber,
  validatePayment,
} from "@metlife-one-opps/services";
import { formatAmount } from "@metlife-one-opps/utils";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { PencilIcon } from "../../assets/icons";
import {
  REQUEST_SUBMITTED_DESC,
  REQUEST_SUBMITTED_HEADING,
  otpStatus,
  serverErrorHeadings,
} from "../../constants";
import {
  getOptionList,
  getPayableAmount,
  paymentGatewaysList,
  paymentTypeMap,
} from "../../data";
import { UpdateMobileNumberModal } from "../components";
import { PaymentVendorTypes, paymentGatewayRule } from "../pages";
import { MetLifeDropDown } from "./MetLifeDropDown/MetLifeDropDown";

const SelectPaymentOptionItem = ({
  item,
  selected,
  onClick,
  isMobile,
}: {
  item: any;
  selected: any;
  onClick: (v: any) => void;
  isMobile?: boolean;
}) => (
  <div
    key={item.id}
    role="button"
    data-testid={
      isMobile ? `dropdown-item-mobile-${item.id}` : `dropdown-item-${item.id}`
    }
    className={`ConfirmPayment_InputDropDown__item ${
      selected?.label === item.label ? "active" : ""
    } `}
    onClick={onClick}
  >
    <div className={item.value ? "premiumValue" : "otherValue"}>{item.label} </div>
    {item.value && <div className="premium_amount">{item.value}</div>}
  </div>
);

export const SelectPaymentGateways = ({ state }: any) => {
  const navigate = useNavigate();
  const { policy, selectedPayment, paymentId, birthYear } = state ?? {};

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "all",
  });
  const { setLoading } = useLoader();
  const optionList = getOptionList(policy, selectedPayment);
  const [dropDowwnValue, setDropDownValue] = useState(optionList[0]);
  const [showRequestSentAlert, setShowRequestSentAlert] = useState(false);
  const [showUpdateMobileNumberModal, setShowUpdateMobileNumberModal] =
    useState(false);
  const [showSelectDropDownBottomSheet, setShowSelectDropDownBottomSheet] =
    useState(false);
  const [paymentVendor, setPaymentVendor] = useState<PaymentVendorTypes>("");
  const [newMobile, setNewMobile] = useState(policy?.policyOwnerMobile);
  const [showPencilIcon, setShowPencilIcon] = useState(true);
  const [hasUpdatedMobile, setHasUpdatedMobile] = useState(
    policy?.policyOwnerMobileUpdated
  );
  const [otpError, setOtpError] = useState<any>(null);
  const [showOtp, setShowOtp] = useState(false);
  const onSelectPaymentGateway = async (item: any) => {
    setPaymentVendor(item.altText);
    setValue("paymentVendor", item.altText);
    trigger("paymentVendor", { shouldFocus: true });
  };

  useEffect(() => {
    if (localStorage.getItem("pageIndexName") !== "PaymentType") {
      fetchMobileStatus();
    }
    localStorage.removeItem("pageIndexName");
  }, []);

  const getIntrospectToken = async () => {
    const resp = await introspectToken();
    try {
      if (resp?.status === 200) {
        return resp?.data?.sessionId;
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const fetchMobileStatus = async () => {
    try {
      setLoading(true);

      const paymentType =
        paymentTypeMap[selectedPayment?.label] || selectedPayment?.label;
      const sessionId = await getIntrospectToken();
      const res = await createPaymentService({
        paymentType: paymentType,
        transactionId: sessionId,
      });

      if (res?.status === 200) {
        setHasUpdatedMobile(res?.data?.data?.policyOwnerMobileUpdated);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("Error while fetching mobile status.", error);
    }
  };
  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      let otherAmount = data?.otherAmount;

      if (dropDowwnValue.value) {
        otherAmount = null;
        reset();
      }

      const payableAmount = getPayableAmount(policy, otherAmount);

      let requestPayload: any = {
        transactionId: paymentId,
        paymentType: selectedPayment.value,
      };

      if (otherAmount) {
        requestPayload.customAmount = "NPR " + formatAmount(payableAmount);
      } else {
        requestPayload.payableAmount = "NPR " + formatAmount(payableAmount);
      }

      const otpValidationRes = await validatePayment(requestPayload);

      if (otpValidationRes?.data?.data?.otpStatus === otpStatus.OTP_SENT) {
        setLoading(false);
        navigate("/verify-otp", {
          state: {
            policyNumber: policy?.policyNumber,
            birthYear: moment(birthYear).format("YYYY"),
            uuid: otpValidationRes?.data?.data?.uuid,
            otpStatus: otpValidationRes?.data?.data?.otpStatus,
            msisdn: otpValidationRes?.data?.data?.msisdn,
            requestPayload: requestPayload,
            paymentVendor: paymentVendor,
            paymentId: paymentId,
            policy: policy,
            otherAmount: otherAmount,
            selectedPayment: { ...selectedPayment, amount: payableAmount },
          },
        });
      } else {
        requestPayload.paymentVendor = paymentVendor;

        const res = await startPayment(requestPayload);
        setLoading(false);
        if (res?.status === 200) {
          const paymentVendorItem = paymentGatewaysList.find(
            (pvi) => pvi.altText === paymentVendor
          );
          localStorage.setItem(
            "@user-policy-data",
            JSON.stringify({
              vendorName: paymentVendorItem?.value,
              transactionId: paymentId,
            })
          );

          navigate("/payment-overview", {
            state: {
              item: paymentVendor,
              policy: policy,
              otherAmount: otherAmount,
              selectedPayment: { ...selectedPayment, amount: payableAmount },
              paymentId: paymentId,
            },
          });
        }
      }
    } catch (error: any) {
      setLoading(false);
      const errorCode: any = error?.response?.data?.errors?.errorCode;
      const serverErrorCodes = [
        "OTP004",
        "PEY",
        "OTP005",
        "PSI001",
        "OTP007",
        "MOB001",
        "PLE001",
        "OTP001",
      ];
      if (serverErrorCodes.includes(errorCode)) {
        setShowOtp(true);
        setOtpError(error?.response?.data?.errors);
      }
    }
  };
  const handleSelect = (selected: any) => {
    if (window.innerWidth < 768) {
      setShowSelectDropDownBottomSheet(false);
    }
    setDropDownValue(selected);
  };

  const onEditMobileNumber = () => {
    setShowUpdateMobileNumberModal(true);
  };
  const handleChangeMobileNumber = async (newMobile: any) => {
    const payload = {
      transactionId: paymentId,
      newMobileNumber: newMobile,
    };
    try {
      setLoading(true);
      const res = await updateMobileNumber(payload);
      if (res?.status === 200) {
        setShowUpdateMobileNumberModal(false);
        setShowRequestSentAlert(true);
        setShowPencilIcon(false);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log("Error while updating mobile number.", e);
    }
  };

  useEffect(() => {
    if (!dropDowwnValue?.value) {
      setValue("otherAmount", "");
    }
  }, [dropDowwnValue]);

  return (
    <div className="Confirm_payment">
      <form onSubmit={handleSubmit(onSubmit)} data-testid="SelectPG__form">
        <div className="ConfirmPayment_heading">
          <MetLifeGradientRow />
          <h1>Select Payment Amount and Payment Gateway</h1>
        </div>
        <div className="ConfirmPayment_cards">
          <div className="cardComponent">
            <Card>
              <div className="ConfirmPayment_cardContainer">
                <div className="cardHeading">
                  <h3>Policy Details</h3>
                </div>
                <br />
                <div className="StandingInstructions__mandate-information">
                  <div className="row">
                    <div className="column-1">
                      <div className="label">Policy Owner’s Name</div>
                      <div className="value">
                        <div>{policy?.policyOwnerName}</div>
                      </div>
                    </div>
                    <div className="column-2">
                      <div className="label">Policy Owner’s Email</div>
                      <div className="value">
                        <div>{policy?.policyOwnerEmail}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column-1">
                      <div className="label">Mobile Number</div>
                      <div className="value">
                        <div className="update-policyOwnerMobileNumber">
                          <span>+977 {newMobile}</span>
                          {!(hasUpdatedMobile || !showPencilIcon || !setLoading) && (
                            <button
                              type="button"
                              data-testid="pencil-icon-edit"
                              onClick={onEditMobileNumber}
                            >
                              <PencilIcon />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="column-2">
                      <div className="label">Payment Type</div>
                      <div className="value">
                        <div>{selectedPayment?.label} </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column-1">
                      <div className="label">Policy Number</div>
                      <div className="value">
                        <div>{policy?.policyNumber}</div>
                      </div>
                    </div>
                    <div className="column-2">
                      <div className="label">Payment Due Date</div>
                      <div className="value">
                        <div>
                          {moment(policy?.paymentDueDate).format("DD/MM/YYYY")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column-1">
                      <div className="label">Policy Status</div>
                      <div className="value">
                        <div> {policy?.policyStatus}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>

          <div className="cardComponent">
            <Card>
              <div className="ConfirmPayment_cardContainer">
                <div className="cardHeading">
                  <h3>Select Payable Amount</h3>
                </div>
                <div className="SelectAmountContainer">
                  <MetLifeDropDown
                    onFocus={(e: any) => {
                      if (window.innerWidth < 768) {
                        e.target.blur();
                      }
                    }}
                    optionsList={optionList}
                    identifier="value"
                    renderItemComponent={(item: any) => (
                      <SelectPaymentOptionItem
                        item={item}
                        selected={dropDowwnValue}
                        onClick={() => handleSelect(item)}
                      />
                    )}
                    renderSelectedComponent={() => (
                      <div
                        className="ConfirmPayment_InputDropDown__Selected"
                        onClick={() => {
                          if (window.innerWidth < 768) {
                            setShowSelectDropDownBottomSheet(true);
                          }
                        }}
                      >
                        <div className="label">{dropDowwnValue?.label} </div>
                        <div className="value">
                          {dropDowwnValue?.value && (
                            <strong>{dropDowwnValue?.value}</strong>
                          )}
                        </div>
                      </div>
                    )}
                    containerCustomClass="ConfirmPayment__dropdown-custom"
                  />
                  {!dropDowwnValue?.value && (
                    <Controller
                      name="otherAmount"
                      control={control}
                      rules={paymentGatewayRule(policy?.payableAmount.split(" ")[1])}
                      render={({ field }) => (
                        <MetLifeInput
                          containerCustomClass={`ConfirmPayment__otheramount-custom ${
                            !!errors?.otherAmount?.message ? "has-error" : ""
                          }`}
                          control={control}
                          inputHasValue={!!field.value}
                          name={"otherAmount"}
                          data-testid="selectPG__otherAmount"
                          placeholder="Enter Your Amount"
                          isError={!!errors?.otherAmount?.message}
                          errMessage={errors?.otherAmount?.message}
                        />
                      )}
                    />
                  )}
                </div>
              </div>
            </Card>
          </div>
          {policy?.suspenseDeductionAmount ? (
            <div className="Summary">
              <div className="cardComponent summary">
                <Card>
                  <div className="ConfirmPayment_cardContainer payment-summary-container">
                    <div className="cardHeading">
                      <h3>Payment Summary</h3>
                    </div>
                    <div className="payment-summary-details">
                      <div className="row">
                        <div className="label">Premium Amount</div>
                        <div className="value">
                          {formatAmount(Number(policy?.totalAmount.split(" ")[1]))}
                        </div>
                      </div>
                      <div className="row">
                        <div className="label">Suspense Amount</div>
                        <div className="value">
                          -
                          {formatAmount(
                            Number(policy?.suspenseDeductionAmount.split(" ")[1])
                          )}
                        </div>
                      </div>
                      <div className="row final">
                        <div className="label">Net Payable Amount</div>
                        <div className="value">
                          {formatAmount(Number(policy?.payableAmount.split(" ")[1]))}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          ) : (
            <div></div>
          )}

          <div className="cardComponent">
            <Card>
              <div className="ConfirmPayment_cardContainer">
                <div className="cardHeading">
                  <h3>Select Payment Gateway</h3>
                  <div className={"ConfirmPayment__payment-gateways"}>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Please select a payment gateway",
                        },
                      }}
                      name="paymentVendor"
                      control={control}
                      render={({ field }) => (
                        <input type="hidden" {...field} value={paymentVendor} />
                      )}
                    />
                    {paymentGatewaysList.map((pg) => (
                      <div
                        className={`cf-payment__gateway ${
                          paymentVendor === pg.altText ? "active" : ""
                        } ${
                          pg.isDisabled
                            ? "ConfirmPayment__payment-gateways-disabled"
                            : ""
                        }`}
                        data-testid={`gateway-${pg.altText}-id`}
                        key={pg.id}
                        onClick={() =>
                          pg.isDisabled ? () => {} : onSelectPaymentGateway(pg)
                        }
                      >
                        <MetLifeRadioInput
                          label={pg?.label}
                          value={pg?.altText}
                          checked={paymentVendor === pg.altText}
                        >
                          <img src={pg.url} alt={pg.altText} />
                        </MetLifeRadioInput>
                      </div>
                    ))}
                  </div>
                  {errors?.paymentVendor?.message && (
                    <div className="is-error">Please select a payment gateway.</div>
                  )}
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className="ConfirmPayment_button">
          <MetLifeButton
            type="button"
            variant="secondary"
            onClick={() => {
              navigate(-1);
            }}
            data-testid="SelectPaymentGateways_back"
          >
            Back
          </MetLifeButton>

          <MetLifeButton data-testid="SelectPaymentGateways_next">
            Next
          </MetLifeButton>
        </div>
      </form>
      <UpdateMobileNumberModal
        showModal={showUpdateMobileNumberModal}
        initialValue={policy?.policyOwnerMobile}
        onClose={() => setShowUpdateMobileNumberModal(false)}
        onSubmit={(n: any) => {
          handleChangeMobileNumber(n.updateMobileNumber);
        }}
      />
      <AlertPopup
        alertWrapperCustomClass="auth-popups-custom-modal-wrapper-logout"
        isVisible={showRequestSentAlert}
        onCloseAlert={() => {
          setShowRequestSentAlert(false);
        }}
        onPressPrimary={() => {
          setShowRequestSentAlert(false);
        }}
        primaryBtnText="Confirm"
        heading={REQUEST_SUBMITTED_HEADING}
        description={REQUEST_SUBMITTED_DESC}
        description2=""
      />
      <AlertPopup
        data-testid="VerifyOTP__alert-popup-payment-failed"
        alertWrapperCustomClass="auth-popups-custom-modal-wrapper-logout"
        isVisible={!!showOtp}
        description={otpError?.desc}
        heading={serverErrorHeadings[otpError?.errorCode] || ""}
        primaryBtnText="Close"
        onPressPrimary={() => {
          setShowOtp(false);
          setOtpError(null);
        }}
      />
      <Modal
        data-testid="bottomSheetMobile"
        isVisible={showSelectDropDownBottomSheet}
        onClose={() => {}}
        modalContainerClass="SelectPaymentAmount__bottomsheet__modal"
        showModalHeader={false}
      >
        <div className="SelectPaymentAmount__bottomsheet">
          <div className="heading">More Options</div>
          {optionList.map((item: any) => (
            <SelectPaymentOptionItem
              isMobile={showSelectDropDownBottomSheet}
              item={item}
              key={item?.id}
              selected={dropDowwnValue}
              onClick={() => handleSelect(item)}
            />
          ))}
        </div>
      </Modal>
    </div>
  );
};

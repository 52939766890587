import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import useIdleTimeout from "../useIdleTimeout";

import { AlertPopup } from "@metlife-one-opps/alert-popup";
import { router } from "@metlife-one-opps/app/src/App";
import appConfig from "@metlife-one-opps/app/src/config";
import {
  introspectToken,
  refreshToken as requestRefereshToken,
  revokeToken,
} from "@metlife-one-opps/services";
import { eventEmitter } from "@metlife-one-opps/utils";
import moment from "moment";
import qs from "qs";

import {
  INACTIVITY_ALERT_DESC,
  INACTIVITY_ALERT_DESC2,
  SESSION_EXPIRED_ALERT_DESC,
  SESSION_EXPIRED_ALERT_DESC2,
} from "../constants";
import { useLoader } from "../useLoader";
import { useSearchParams } from "react-router-dom";

const contextState = {
  data: {},
  logout: () => {},
  setGlobalData: () => {},
  setUserSessionOut: () => {},
  setUserInactive: () => {},
  onUserLogout: () => {},
};

const idleTimeInMinutesAllowed = appConfig.inactivityTimeMinutes - 1;

export const AuthContext = createContext(contextState);

export const AuthProvider = ({ children }) => {
  const { setLoading } = useLoader();
  const [onLogout, setOnLogout] = useState(false);
  const [globalData, setGlobalData] = useState({});
  const [userInactive, setUserInactive] = useState(false);
  const [userSessionOut, setUserSessionOut] = useState(false);
  const [timer, setTimer] = useState(appConfig.countdownTimerSeconds);
  const [sessionTimer, setSessionTimer] = useState(null);
  const [startSessionTimer, setStartSessionTimer] = useState(false);

  let timerInterval;
  let sessionTimeInterval;
  let currentUnixTime = moment(new Date()).unix();
  const [searchParams] = useSearchParams();
  const ref_t = searchParams.get("refreshToken") ?? "";
  const refreshToken = ref_t?.includes("?") ? ref_t.split("?")[0] : ref_t;
  const status_t = searchParams.get("paymentFailed") ?? "";
  const paymentStatus = status_t?.includes("?") ? status_t.split("?")[0] : status_t;

  useEffect(() => {
    if (userSessionOut || userInactive) {
      timerInterval = setInterval(() => {
        setTimer((prevTimerValue) => {
          if (prevTimerValue <= 1) {
            logout({ isSessionOut: userSessionOut, isUserInactive: userInactive });
            return 0;
          } else {
            return prevTimerValue - appConfig.inactivityTimeIntervalInSeconds;
          }
        });
      }, appConfig.inactivityTimeIntervalInSeconds * 1000);
    }
    return () => {
      clearInterval(timerInterval);
    };
  }, [userSessionOut, userInactive]);

  useEffect(() => {
    const handleRefreshTokenExpired = () => {
      setUserSessionOut(true);
    };
    eventEmitter.on("@user-session-out", handleRefreshTokenExpired);
    return () => eventEmitter.off("@user-session-out", handleRefreshTokenExpired);
  }, []);

  useEffect(() => {
    if (startSessionTimer) {
      sessionTimeInterval = setInterval(() => {
        setSessionTimer((previousUnixTime) => {
          if (previousUnixTime <= currentUnixTime) {
            clearInterval(sessionTimeInterval);
            setUserSessionOut(true);
            return null;
          } else {
            return previousUnixTime - appConfig.sessionCountdownIntervalSeconds;
          }
        });
      }, appConfig.sessionCountdownIntervalSeconds * 1000);
    }
    return () => {
      clearInterval(sessionTimeInterval);
    };
  }, [startSessionTimer]);

  /**
   * initalize.
   * @return
   */
  const initialize = async () => {
    if (refreshToken) {
      const adJson = window.localStorage.getItem("@user-access");
      if (paymentStatus !== "true") {
        localStorage.setItem(
          "@user-access",
          JSON.stringify({
            ...JSON.parse(adJson),
            refresh_token: refreshToken,
          })
        );
      }
    }
    const accessDataJSONString = window.localStorage.getItem("@user-access");
    if (!accessDataJSONString) {
      return router.navigate("/", { state: {} });
    }
    if (startSessionTimer) return false;
    try {
      if (accessDataJSONString) {
        setLoading(true);

        const res = await introspectToken();

        clearInterval(timerInterval);
        if (res && res?.status === 200 && res?.data?.active === true) {
          setSessionTimer(res.data.exp - appConfig.countdownTimerSeconds);
          setStartSessionTimer(true);
          setLoading(false);
        } else {
          setLoading(false);
          logout({ isSessionOut: true, isUserInactive: false });
        }
      }
    } catch (error) {
      setLoading(false);
      console.log("Error | Introspect Token", error);
    }
  };

  const clearLocalStorageAndNavigateToLogin = (payload = {}) => {
    setUserSessionOut(false);
    setUserInactive(false);
    setGlobalData({});
    setOnLogout(false);

    localStorage.clear();
    router.navigate("/", { state: payload });
  };

  /**
   * logout
   * @return
   */
  const logout = async (payload = {}) => {
    try {
      setLoading(true);
      const res = await revokeToken();
      setLoading(false);
      clearInterval(timerInterval);
      if (res && res?.status === 200) {
        clearLocalStorageAndNavigateToLogin(payload);
      }
    } catch (error) {
      setLoading(false);
      clearLocalStorageAndNavigateToLogin(payload);
      console.log("Error | Revoke Token", error);
    }
  };

  const onUserLogout = () => {
    setOnLogout(true);
  };

  /**
   * Handling idle activity
   * @return
   */
  const handleIdle = () => {
    setUserInactive(true);
  };

  const continueSession = async () => {
    const accessDataJSONString = window.localStorage.getItem("@user-access");
    const accessData = JSON.parse(accessDataJSONString);
    let refreshToken = accessData?.refresh_token ?? "";
    let data = qs.stringify({
      grant_type: "refresh_token",
      refresh_token: refreshToken,
    });
    setLoading(true);
    await requestRefereshToken(data)
      .then((response) => {
        setLoading(false);
        localStorage.setItem(
          "@user-access",
          JSON.stringify({
            ...accessData,
            token: response.data.access_token,
          })
        );
        setUserSessionOut(false);
        setUserInactive(false);
        clearInterval(timerInterval);
      })
      .catch((error) => {
        setLoading(false);
        clearLocalStorageAndNavigateToLogin();
      });
  };

  useIdleTimeout({
    onIdle: handleIdle,
    idleTime: Math.abs(idleTimeInMinutesAllowed * 60),
  });

  useEffect(() => {
    initialize();
  }, []);

  const getTimeoutText = () => {
    const paddedNumber = timer > 9 ? timer : `0${timer}`;
    return timer == 60 ? "1:00 minute" : `00:${paddedNumber} seconds`;
  };
  const autoLogout = () => {
    setTimeout(() => {
      logout({ isSessionOut: true, isUserInactive: false });
    }, timer * 1000);
    clearInterval(timerInterval);
  };
  const authContextValue = useMemo(
    () => ({
      data: globalData,
      setGlobalData: setGlobalData,
      setUserSessionOut: setUserSessionOut,
      setUserInactive: setUserInactive,
      logout: logout,
      onUserLogout: onUserLogout,
    }),
    [globalData, userSessionOut, userInactive]
  );
  return (
    <AuthContext.Provider value={authContextValue}>
      <>{children}</>
      <AlertPopup
        data-testid="auth-sessionout-popup"
        alertWrapperCustomClass="auth-popups-custom-modal-wrapper-logout"
        isVisible={userInactive}
        onCloseAlert={() => {}}
        onPressPrimary={continueSession}
        primaryBtnText="Continue Session"
        heading="Inactive User"
        description={INACTIVITY_ALERT_DESC.replace("[timer]", getTimeoutText())}
        description2={INACTIVITY_ALERT_DESC2}
      />
      <AlertPopup
        data-testid="auth-inactiveser-popup"
        alertWrapperCustomClass="auth-popups-custom-modal-wrapper-logout"
        isVisible={userSessionOut}
        onCloseAlert={() => {}}
        showSecodaryBtn
        onPressSecondary={() => {
          setUserSessionOut(false);
          autoLogout();
        }}
        secondaryBtnText="Cancel"
        onPressPrimary={() => {
          clearInterval(timerInterval);
          logout({ isSessionOut: true, isUserInactive: false });
        }}
        primaryBtnText="Login"
        heading="Session Time Out"
        description={SESSION_EXPIRED_ALERT_DESC.replace("[timer]", getTimeoutText())}
        description2={SESSION_EXPIRED_ALERT_DESC2}
      />
      {/* logout popup */}
      <AlertPopup
        data-testid="auth-logout-popup"
        alertWrapperCustomClass="auth-popups-custom-modal-wrapper-logout"
        showSecodaryBtn
        isVisible={onLogout}
        onCloseAlert={() => {}}
        onPressPrimary={() => {
          logout();
        }}
        onPressSecondary={() => setOnLogout(false)}
        primaryBtnText="Yes"
        secondaryBtnText="No"
        heading={"Are you sure you want to logout?"}
        description={""}
        description2=""
      />
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
